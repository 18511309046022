import centerimage from './centerImage.JPG'
import './App.css'
import LogoTop from './logo.PNG'
import AppImage from './call_btn.png'
// import { isIOS } from 'react-device-detect';

function App() {
    const phoneNumber = '1877-2500';
    const handleClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

  return (
    <div className="App">
      <img className={'logoTop'} src={LogoTop} alt={'logoTop'}/>

      <img className={'centerImage'} src={centerimage} alt={'centerImage'}/>

        <div className={'appContainer'} onClick={handleClick}>
            <img className={'appDownImage'} src={AppImage} alt={'appDownImage'}/>
        </div>
    </div>
  );
}

export default App;
